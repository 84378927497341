angular.module("ngConstants", [])

.constant("host", "https://simonsays.ai/")

.constant("ogUrl", "https://app.simonsays.ai/")

.constant("PUBLIC_SITE_URL", "https://public.simonsays.ai")

.constant("STORAGE_BASE_URL", "https://s3-us-west-2.amazonaws.com/prod-public-storage/")

;